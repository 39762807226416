import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getMsgData } from "../../services/HomeServices";
import { toast } from "react-toastify";
import img from "../../assets/images/azlogomessage.jpeg";
import {
  formatDateToMMDDYYYY,
} from "../../lib/constant";
import Loader from "../../containers/Loader";
import _ from "lodash";

const MessageSidebar = () => {
  const [messageData, setmessageData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getMessageData();
  }, []);

  const getMessageData = async () => {
    setisLoading(true);

    await getMsgData("", "", 1)
      .then((response) => {
        setisLoading(false);
        setmessageData(response?.data?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };
  return (
    <div
      className="binduz-er-sidebar-latest-post"
      style={{ marginTop: "45px" }}
    >
      <div
        className="binduz-er-sidebar-title"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4 className="binduz-er-title">MESSAGE CENTER</h4>
        <h2
          className="binduz-er-title"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/message-center");
          }}
        >{`VIEW ALL>`}</h2>
      </div>
      {!isLoading ? (
        <>
          {!_.isEmpty(messageData) ? (
            <div className="binduz-er-sidebar-latest-post-box">
              {messageData?.slice(0, 7)?.map((item, index) => (
                <div className="5px">
                  <div className={`binduz-er-sidebar-latest-post-item `}>
                    <div
                      className="binduz-er-thumb"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={item?.image ? item?.image : img}
                        alt="latest"
                        width={220}
                        height={60}
                      />
                    </div>
                    <div
                      className="binduz-er-content"
                      //   style={{ paddingRight: "10px", paddingLeft: "20px" }}
                    >
                      <span>
                        <i className="fal fa-calendar-alt"></i>{" "}
                        {formatDateToMMDDYYYY(new Date(item?.created_at))}
                      </span>
                      <div className=" line-clump-3 ">
                        <Link
                          to={{
                            pathname: `/ticket-details/${item?.id}`,
                            // state: item,
                          }}
                          state={item}
                          style={{ cursor: "pointer" }}
                          className="binduz-er-title line-clump-2"
                        >
                          {/* {plainString(item?.message)} */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.message,
                            }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <h6
              style={{
                height: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Data Found
            </h6>
          )}
        </>
      ) : (
        <div style={{ height: "300px", display: "flex", alignItems: "center" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default MessageSidebar;
