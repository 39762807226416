import Routers from "./Routers";
import "./App.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth", // Smooth scroll effect
    });
  }, [location?.pathname]);

  return <Routers />;
}

export default App;
