import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import Loader from "../../containers/Loader";
import { formatDate, formateMobileNumber } from "../../lib/constant";
import SideADModule from "../../containers/PostCards/SideADModule";

const MainSection = () => {
  const location = useLocation();
  const [data, setdata] = useState(location?.state);
  const [isLoading, setisLoading] = useState(false);


  useEffect(() => {
    // getData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <motion.div
            className=" col-lg-9"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 4 }}
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">JOB DETAIL</h3>
            </div>
            <div className="binduz-er-meta-author">
              {/* <h4>Let's Nacho 2024 | ABCD School of Dance</h4> */}
            </div>
            {!isLoading ? (
              <>
                {data?.image && (
                  <div
                    style={{
                      marginTop: "40px",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                      borderRadius: "10px",
                    }}
                    className="binduz-er-author-item mb-40"
                  >
                    <div className="binduz-er-thumb col-lg-12">
                      <img src={data?.image} height={400} alt="" />
                    </div>
                  </div>
                )}
                {/* <div className="classifield-details-text mt-5">
                  <div className="col-lg-12">
                    <p className="card-text text-muted">
                      <strong>Contact Name :</strong>{" "}
                      <span style={{ color: "#e74d58" }}>{data?.name}</span>
                    </p>
                  </div>
                  {data?.company_name && (
                    <div className="col-lg-12">
                      <p className="card-text text-muted">
                        <strong>Company Name :</strong>{" "}
                        <span style={{ color: "#e74d58" }}>
                          {data?.company_name}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className="col-lg-12">
                    <p className="card-text text-muted">
                      <strong>Mobile Number :</strong>{" "}
                      <span style={{ color: "#e74d58" }}>{data?.phone}</span>
                    </p>
                  </div>

                  {data?.mobile && (
                    <div className="col-lg-12">
                      <p className="card-text text-muted">
                        <strong>Alternative Mobile Number :</strong>{" "}
                        <span style={{ color: "#e74d58" }}>{data?.mobile}</span>
                      </p>
                    </div>
                  )}

                  <div className="col-lg-12">
                    <p className="card-text text-muted">
                      <strong>Email :</strong>{" "}
                      <span style={{ color: "#e74d58" }}>
                        <a
                          style={{ color: "#e74d58" }}
                          href={`mailto:${data?.email}`}
                        >
                          {data?.email}
                        </a>
                      </span>
                    </p>
                  </div>
                  {data?.contact_detail && (
                    <div className="col-lg-12">
                      <p className="card-text text-muted">
                        <strong>Contact Detail :</strong>{" "}
                        <span style={{ color: "#e74d58" }}>
                          {data?.contact_detail}
                        </span>
                      </p>
                    </div>
                  )}
                  {data?.location && (
                    <div className="col-lg-12">
                      <p className="card-text text-muted">
                        <strong>Location :</strong>{" "}
                        <span style={{ color: "#e74d58" }}>
                          {data?.location}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className="col-lg-12">
                    <p className="card-text text-muted">
                      <strong>Posted On :</strong>{" "}
                      <span style={{ color: "#e74d58" }}>
                        {formatDate(data?.date)}
                      </span>
                    </p>
                  </div>
                  {data?.url && (
                    <div className="col-md-4">
                      <p className="card-text text-muted">
                        <strong>Web :</strong>{" "}
                        <span style={{ color: "#e74d58" }}>{data?.url}</span>
                      </p>
                    </div>
                  )}
                </div> */}

                <div class="contact-info mb-5">
                  <p>
                    <span class="icon">👤</span>Contact Name:{" "}
                    <span>{data?.name}</span>
                  </p>
                  {data?.company_name && (
                    <p>
                      <span class="icon">🏢</span>Company Name:{" "}
                      <span> {data?.company_name}</span>
                    </p>
                  )}
                  <p>
                    <span class="icon">📞</span>Mobile Number:{" "}
                    <span>{formateMobileNumber(data?.phone)}</span>
                  </p>
                  {data?.mobile && (
                    <p>
                      <span class="icon">📞</span>Alternative Mobile Number:{" "}
                      <span>{formateMobileNumber(data?.mobile)}</span>
                    </p>
                  )}
                  <p>
                    <span class="icon">✉️</span>Email:{" "}
                    <a
                      style={{ color: "#e74d58", fontWeight: "bold" }}
                      href={`mailto:${data?.email}`}
                    >
                      {data?.email}
                    </a>
                  </p>
                  {data?.contact_detail && (
                    <p>
                      <span class="icon">📍</span>Contact Detail:{" "}
                      <span>{data?.contact_detail}</span>
                    </p>
                  )}
                  {data?.location && (
                    <p>
                      <span class="icon">🌍</span>Location:{" "}
                      <span>{data?.location}</span>
                    </p>
                  )}
                  <p>
                    <span class="icon">📅</span>Posted On:{" "}
                    <span>{formatDate(data?.date)}</span>
                  </p>
                  {data?.url && (
                    <p>
                      <span class="icon">💻</span>Web: <span>{data?.url}</span>
                    </p>
                  )}
                </div>

                <div className="classifield-details-text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.description,
                    }}
                  />
                </div>

                <div className="binduz-er-title">
                  <p>
                    When you call, don't forget to mention that you found this
                    ad on azindia.com.
                    <br />
                  </p>
                </div>
              </>
            ) : (
              <div style={{ marginTop: "101px" }}>
                <Loader />
              </div>
            )}
          </motion.div>
          <div
            className=" col-lg-3"
            // style={{ paddingRight: "85px", paddingLeft: "55px" }}
          >
            <SideADModule isAd={false} isClassifield={true} isEvent={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
