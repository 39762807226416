/* eslint-disable no-unused-vars */
import React from "react";
import ClassiFieldForm from "./ClassiFieldForm";
import SideADModule from "../../containers/PostCards/SideADModule";

const MainSection = () => {
  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div
            className=" col-lg-9"
            // style={{ paddingRight: "20px", paddingLeft: "20px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">POST YOUR JOBS</h3>
            </div>
            <ClassiFieldForm />
          </div>
          <div
            className=" col-lg-3"
            // style={{ paddingRight: "85px", paddingLeft: "55px" }}
          >
            {/* <>
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">EXPLORE YOUR COUNTRY</h3>
            </div>
            <div className="binduz-er-footer-gallery ">
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map(() => (
                  <div className="">
                    <a href="#">
                      <img
                        height={85}
                        src={
                          require(`../../assets/images/classifield.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map(() => (
                  <div className="">
                    <a href="#">
                      <img
                        height={85}
                        src={
                          require(`../../assets/images/classifield.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map(() => (
                  <div className="">
                    <a href="#">
                      <img
                        height={85}
                        src={
                          require(`../../assets/images/classifield.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>

            <div
              className="binduz-er-footer-gallery "
              style={{ marginTop: "77px" }}
            >
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map(() => (
                  <div className="binduz-er-item">
                    <a href="#">
                      <img
                        height={155}
                        width={165}
                        src={
                          require(`../../assets/images/classifield.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map(() => (
                  <div className="binduz-er-item">
                    <a href="#">
                      <img
                        height={155}
                        width={165}
                        src={
                          require(`../../assets/images/classifield.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
            </> */}
            <SideADModule isClassifield={true} isEvent={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
