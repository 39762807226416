import React from 'react'

const BannerImage = ({img,isFullScreen}) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100vw", // Full viewport width
        height: isFullScreen ? "calc(100vh - 122px)" : "500px", // Screen height minus 122px
        overflow: "hidden", // Hide any overflow from the image
      }}
    >
      {/* The image itself */}
      <img
        src={img}
        alt="Banner"
        style={{
          width: "100%", // Stretch the image to full width
          height: "100%", // Stretch the image to full height
          objectFit: "fill", // Ensure the image stretches to fit both dimensions without cropping
        }}
      />

      {/* Dark overlay */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Darken the image (adjust opacity for intensity)
        }}
      />
    </div>
  );
}

export default BannerImage