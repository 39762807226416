import React, { useCallback, useEffect, useState } from "react";
import Pagination from "../../containers/Pagination";
import {
  getClassifieldCatData,
  getClassifieldData,
  getLatestClassifieldData,
} from "../../services/HomeServices";
import _, { pad } from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../containers/Loader";
import img from "../../assets/images/azlogomessage.jpeg";
import img1 from "../../assets/images/az1.jpeg";
import img2 from "../../assets/images/az2.jpeg";
import img3 from "../../assets/images/az3.jpeg";
import img4 from "../../assets/images/az4.jpeg";
import img5 from "../../assets/images/adazindia.jpeg";
import "./index.css";
import { formatDate } from "../../lib/constant";
import SideADModule from "../../containers/PostCards/SideADModule";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const imgArray = [
  img,
  img1,
  img2,
  img3,
  img4,
  img5,
  img4,
  img3,
  img,
  img3,
  img2,
  img5,
];
const categoriesEmogiArray = [
  // { name: "Pin Hopes", emoji: "📌" }, // Pin
  { name: "Training Classes", emoji: "🎓" }, // Graduation Cap
  { name: "Car Pool", emoji: "🚗" }, // Car
  { name: "Food & Catering", emoji: "🍔" }, // Burger
  { name: "Beauty Services", emoji: "💄" }, // Lipstick
  { name: "Care Takers", emoji: "👩‍⚕️" }, // Healthcare Worker
  { name: "Art/Dance/Music", emoji: "🎨" }, // Palette
  { name: "Real Estate", emoji: "🏡" }, // House
  { name: "For Sale", emoji: "💰" }, // Money Bag
  { name: "Travel", emoji: "✈️" }, // Airplane
  { name: "Rentals", emoji: "🏠" }, // House (Alternative to Real Estate)
  { name: "Automobiles", emoji: "🚘" }, // Car (Alternative to Car Pool)
];

const MainSection = () => {
  const [catData, setcatData] = useState([]);
  const [search, setsearch] = useState("");
  const [latestClassiLoading, setlatestClassiLoading] = useState(false);
  const [latestClassiData, setlatestClassiData] = useState([]);
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [links, setlinks] = useState([]);
  const [hasAnimatedLeft, setHasAnimatedLeft] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  let widthOfPage = window.innerWidth > 1000;

  useEffect(() => {
    getData();
    // classifieldData();
    latestClassifeildData();

    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

  const controlsLeft = useAnimation();
  const { ref: refLeft, inView: inViewLeft } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewLeft && !hasAnimatedLeft) {
      controlsLeft.start("visible");
      setHasAnimatedLeft(true); // Set the state to prevent re-triggering
    }
  }, [controlsLeft, inViewLeft]);

  const boxVariantsLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  const debouncedFetchResults = useCallback(
    _.debounce((selectedCat) => classifieldData(selectedCat), 500),
    []
  );

  useEffect(() => {
    classifieldData(params?.id);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [page]);

  useEffect(() => {
    setpage(1);
    classifieldData(params?.id);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [search, params?.id]);

  const getData = async () => {
    await getClassifieldCatData()
      .then((response) => {
        const array = response?.data?.data?.filter(
          (a) => a?.name !== "Pin Hopes"
        );
        setcatData(array);
        setcatData(response?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const classifieldData = async (id) => {
    setisLoading(true);
    await getClassifieldData(id || params?.id, search, "", page)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const latestClassifeildData = async () => {
    setlatestClassiLoading(true);
    await getLatestClassifieldData()
      .then((response) => {
        setlatestClassiLoading(false);
        setlatestClassiData(response?.data?.data);
      })
      .catch((error) => {
        setlatestClassiLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div
            className=" col-lg-9"
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
          >
            <div
              className="binduz-er-top-news-title"
              style={{
                display: widthOfPage ? "flex" : "",
                justifyContent: "space-between",
              }}
            >
              <h3 className="binduz-er-title">
                <i
                  className="far fa-tasks"
                  style={{ color: "red", marginRight: "5px" }}
                ></i>
                CLASSIFIEDS
              </h3>
              <h2
                className="classifield-text"
                style={{
                  height: "40px",
                  // marginLeft: "25px",
                  display: "flex",
                  // margin-right: 43px;
                  marginTop: "20px",
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => navigate("/classifield-post")}
              >{`POST YOUR CLASSIFIED >>`}</h2>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              className="col-lg-12"
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="binduz-er-select ">
                    <select
                      placeholder="Data"
                      value={params?.id}
                      onChange={(e) =>
                        navigate(`/classifield/${e?.target?.value}`)
                      }
                    >
                      <option value={""}>Select Category</option>
                      {catData?.map((x, i) => (
                        <option value={x?.id}>
                          {categoriesEmogiArray[i]?.emoji}
                          {x?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="binduz-er-author-sidebar-search-bar col-lg-4">
                    <form
                      onSubmit={(e) => {
                        e?.preventDefault();
                        classifieldData(params?.id);
                      }}
                    >
                      <div
                        className="binduz-er-input-box"
                        style={{ width: "270px" }}
                      >
                        <input
                          type="text"
                          onChange={(e) => setsearch(e?.target?.value)}
                          value={search}
                          placeholder="Search from here..."
                          style={{ lineHeight: "38px" }}
                        />
                        <button type="submit" style={{ height: "40px" }}>
                          <i className="fal fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {!isLoading ? (
              <motion.div
                ref={refLeft}
                // initial="hidden"
                animate={controlsLeft}
                variants={boxVariantsLeft}
                className="row"
              >
                {!_.isEmpty(data) ? (
                  <>
                    {data?.map((item, index) => (
                      <motion.div
                        whileHover={{ scale: 1.05 }} // Scale up the image on hover
                        transition={{ type: "spring", stiffness: 300 }} // Smooth transition with spring effect
                        className="card event-card mt-4"
                        style={{ borderRadius: "20px", padding: "0px" }}
                      >
                        <div className="card-body d-lg-flex align-items-center p-0 cardRowModule-responsivness">
                          {/* <div
                            className="col-lg-2"
                            style={{ textAlign: "center", marginRight: "4px" }}
                          > */}
                          <img
                            src={item?.images[0]?.image || img}
                            alt="AZIndia Logo"
                            style={{
                              top: "0%",
                              display: "inline-block",
                              objectFit: "fill",
                              height: "120px",
                              left: "0%",
                              marginTop: !widthOfPage && "10px",
                              borderRadius: !widthOfPage && "10px",
                              marginRight: widthOfPage ? "25px" : "0px",
                            }}
                            className="event-card-img col-lg-2"
                          />
                          {/* </div> */}
                          <div className="event-card-content col-lg-10">
                            <div className="binduz-er-title line-clump-2">
                              <Link
                                to={{
                                  pathname: `/classifield-detail/${data[index]?.id}`,
                                }}
                                state={data[index]}
                              >
                                <div
                                  style={{
                                    paddingRight: widthOfPage && "25px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                />
                              </Link>
                            </div>
                            <div
                              className="d-flex justify-content-between align-items-center event-card-footer"
                              style={{ paddingBottom: "10px" }}
                            >
                              <div className="binduz-er-meta-author">
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: "#e74d58",
                                  }}
                                  onClick={(item) => {
                                    navigate(
                                      `/classifield-detail/${data[index]?.id}`
                                    );
                                  }}
                                >
                                  {"View Details>"}
                                </span>
                              </div>
                              <div
                                className="binduz-er-meta-date"
                                style={{ paddingRight: "40px" }}
                              >
                                <span>
                                  <i
                                    className="fal fa-calendar-alt"
                                    style={{ marginRight: "7px" }}
                                  ></i>
                                  {formatDate(item?.start_date)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                    <div className="mt-4">
                      <Pagination links={links} setpage={setpage} />
                    </div>
                  </>
                ) : (
                  <h5 style={{ textAlign: "center", marginTop: "70px" }}>
                    No Data found
                  </h5>
                )}
              </motion.div>
            ) : (
              <Loader />
            )}
          </div>
          <div className=" col-lg-3">
            <SideADModule isAd={false} isClassifield={true} isEvent={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
