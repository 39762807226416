import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatDateToMMDDYYYY, minimizeName } from "../lib/constant";
import VideoPopUp from "./VideoPopUp";

const PodcastBox = ({
  datas = {
    image: "",
    title: "",
    date: "",
    description: "",
    category: "",
    link: "",
  },
}) => {
  const [show, setValue] = useState(false);

  return (
    <div className="col-lg-6" style={{padding:'10px'}}>
      <div
        className="binduz-er-recent-news-item mb-20 shadow "
        style={{
          backgroundColor: "white",
          borderRadius: "15px",
        }}
      >
        {show && <VideoPopUp setValue={setValue} videoSrc={datas?.link} />}
        <div
          className="binduz-er-thumb "
          style={{
            position: "relative",
            marginRight: "0px",
            minWidth: "235px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              maxHeight: "165px",
              padding: "15px",
              maxWidth: "250px",
              borderRadius: "25px",
              display: "flex",
              objectFit: "cover",
            }}
            src={datas?.image}
            alt=""
          />
          <div
            className="binduz-er-play"
            style={{
              display: "flex",
              position: "absolute",
              right: "30px",
              cursor: "pointer",
              top: "30px",
            }}
          >
            <a
              onClick={() => setValue(true)}
              className="binduz-er-video-popup"
              style={{
                backgroundColor: "red",
                height: "40px",
                width: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                lineHeight: "50px",
                color: "#f63a3a",
                borderRadius: "5px",
                background: "#fff",
                textAlign: "center",
              }}
            >
              <i className="fas fa-play"></i>
            </a>
          </div>
        </div>
        <div className="binduz-er-content">
          <div className="binduz-er-meta-item">
            <div className="binduz-er-meta-categories">
              <a style={{ borderRadius: "5px", fontSize: "15px" }}>
                {minimizeName(datas?.category)}
              </a>
            </div>
            <div className="binduz-er-meta-date">
              <span>
                <i className="fal fa-calendar-alt"></i>
                {formatDateToMMDDYYYY(new Date(datas?.date))}
              </span>
            </div>
          </div>
          <h6
            className="binduz-er-title"
            style={{ margin: "0px", marginTop: "10px", fontSize: "15px" }}
          >
            <Link style={{ cursor: "default" }}>{datas?.title}</Link>
          </h6>
          <p className="line-clump-2">{datas?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default PodcastBox;
