import React, { useEffect, useState, Suspense } from "react";
import MainPostsContext from "../../contexts/HomeOne/MainPostsContext";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../Helpers/BackToTop";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeOne/Footer";
import HeaderOne from "../Partials/Headers/HeaderOne";
import { toast } from "react-toastify";
import { getBannerIMG, getSponsorIMG } from "../../services/HomeServices";
import { isImageOrVideo } from "../../lib/constant";
import { motion } from "framer-motion";
import Loader from "../../containers/Loader";

// Lazy-loaded components
const BannerPoster = React.lazy(() =>
  import("../Partials/Headers/HeaderOne/BannerPoster")
);
const MainPostsSection = React.lazy(() => import("./MainPostsSection"));
const Podcast = React.lazy(() => import("./Podcast"));
const TrendingSection = React.lazy(() => import("./TrendingSection"));
const FeatureSection = React.lazy(() => import("./FeatureSection"));
const PremiumClassified = React.lazy(() => import("./PremiumClassified"));
const AD = React.lazy(() => import("./AD"));

const HomeOne = () => {
  const navigationData = navigations;
  const [isLoading, setisLoading] = useState(false);
  const [drawer, setDrawer] = useToggle(false);
  const [bannerImage, setbannerImage] = useState([]);
  const [sponsorIMG, setsponsorIMG] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
    getBannerImage();
    getSponsorImage();
  }, []);

  const getBannerImage = async () => {
    setisLoading(true);
    await getBannerIMG()
      .then((response) => {
        const array =
          response?.data?.data?.map((a) => ({
            ...a,
            isImage: isImageOrVideo(a?.image) === "image",
          })) || [];

        setisLoading(false);
        setbannerImage(array);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getSponsorImage = async () => {
    setisLoading(true);
    await getSponsorIMG()
      .then((response) => {
        let array = [];
        for (let a of response?.data?.data) {
          if (a?.above_classified === "1") {
            array?.push(a);
          }
        }
        setsponsorIMG(response?.data?.data);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <motion.div style={{ overflow: "hidden" }}>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderOne drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <BannerPoster data={bannerImage} />
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <MainPostsContext.Provider>
          <MainPostsSection />
        </MainPostsContext.Provider>
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <Podcast />
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <TrendingSection />
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <AD isBlack={true} data={sponsorIMG} />
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <FeatureSection />
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <PremiumClassified />
      </Suspense>

      <Footer />
      <BackToTop />
    </motion.div>
  );
};

export default HomeOne;
