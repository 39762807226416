/* eslint-disable no-unused-vars */
import React from "react";
import ClassiFieldForm from "./ClassiFieldForm";
import SideADModule from "../../containers/PostCards/SideADModule";

const MainSection = () => {
  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div
          className="row"
          // style={{display:'flex',justifyContent:'center'}}
        >
          <div
            className=" col-lg-9"
            // style={{ paddingRight: "20px", paddingLeft: "20px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">POST YOUR EVENT</h3>
            </div>
            <ClassiFieldForm />
          </div>
          <div className="col-lg-3">
            <SideADModule isClassifield={true} isDoctor={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
