import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decodeHTMLEntities, formatDate, plainString } from "../lib/constant";
import Loader from "./Loader";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const TopStories = ({
  title,
  sideTitle,
  isSearch = false,
  data = [],
  onClickHeader,
  onClick,
}) => {
  const navigate = useNavigate();
  const [hasAnimatedLeft, setHasAnimatedLeft] = useState(false);
  const controlsLeft = useAnimation();
  const { ref: refLeft, inView: inViewLeft } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewLeft && !hasAnimatedLeft) {
      controlsLeft.start("visible");
      setHasAnimatedLeft(true); // Set the state to prevent re-triggering
    }
  }, [controlsLeft, inViewLeft]);

  const boxVariantsLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  return (
    <div
      className="binduz-er-sidebar-latest-post"
      style={{ marginTop: isSearch ? "0px" : "40px" }}
    >
      <div
        className="binduz-er-sidebar-title"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4 className="binduz-er-title">{title}</h4>
        <h2
          className="binduz-er-title"
          onClick={onClickHeader}
          style={{ cursor: "pointer" }}
        >
          {sideTitle}
        </h2>
      </div>

      <div className="binduz-er-sidebar-latest-post-box">
        {!_.isEmpty(data) ? (
          <>
            {data?.map((a) => {
              return (
                <motion.div
                  ref={refLeft}
                  initial="hidden"
                  animate={controlsLeft}
                  variants={boxVariantsLeft}
                  whileHover={{ scale: 1.05 }} // Scale up the image on hover
                  transition={{ type: "spring", stiffness: 300 }} // Smooth transition with spring effect
                >
                  <a onClick={() => onClick(a)} className="top-stories-list ">
                    <span>
                      <span
                        style={{ cursor: "pointer" }}
                        className="top-stories-list-line "
                      >
                        {plainString(a?.content)}
                      </span>
                      <p
                        style={{
                          margin: "0px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          Posted By{" "}
                          <span style={{ color: "#f63a3a" }}>
                            {a?.author?.toUpperCase()}
                          </span>
                        </span>
                        <span style={{ fontSize: "14px", marginLeft: "12px" }}>
                          {" "}
                          {formatDate(a?.date)}
                        </span>
                      </p>
                    </span>
                  </a>
                </motion.div>
              );
            })}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default TopStories;
