
/* eslint-disable no-unused-vars */
import React from "react";
import ClassiFieldForm from "./ClassiFieldForm";
import SideADModule from "../../containers/PostCards/SideADModule";

const MainSection = () => {
  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div
            className=" col-lg-9"
            // style={{ paddingRight: "20px", paddingLeft: "20px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">POST YOUR MEGAPHONE</h3>
            </div>
            <ClassiFieldForm />
          </div>
          <div
            className=" col-lg-3"
            // style={{ paddingRight: "85px", paddingLeft: "55px" }}
          >
            {/* <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">POST YOUR CLASSIFIED</h3>
            </div> */}
            <SideADModule isAd={false} isClassifield={true} isEvent={false} />

            {/* <div className="binduz-er-video-post">
              {latestDatas &&
                latestDatas.length > 0 &&
                latestDatas.slice(0, 2).map((latestItem, index) => (
                  <CardStyleHomeTwo
                    key={latestItem.id}
                    datas={{
                      image: latestItem.post_images.latest_news[index],
                      category: latestItem.post_cat[0],
                      title: latestItem.post_title,
                      date: latestItem.post_date,
                    }}
                  />
                ))}
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
