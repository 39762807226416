import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getClassifieldDetail } from "../../services/HomeServices";
import Loader from "../../containers/Loader";
import { formatDate, isImageOrVideo } from "../../lib/constant";
import SideADModule from "../../containers/PostCards/SideADModule";
import { motion } from "framer-motion";
import BannerCarousel from "../Partials/Headers/HeaderOne/BannerCarousel";
import _ from "lodash";
import img from "../../assets/images/detailAz.jpg";
import img2 from "../../assets/images/detailAz2.png";
import SliderCom from "../Helpers/SliderCom";

const MainSection = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const SliderForImage = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
    fade: true,
  };

  const prevHandler = () => {
    nav1.slickPrev();
  };
  const nextHandler = () => {
    nav1.slickNext();
  };
  const params = useParams();

  useEffect(() => {
    getData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [params]);

  const getData = async () => {
    setisLoading(true);
    await getClassifieldDetail(params?.id)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const manipulateArray = (arr) => {
    const array =
      arr?.map((a) => ({
        ...a,
        isImage: isImageOrVideo(a?.image) === "image",
      })) || [];
    return array;
  };

  return (
    <>
      {/* {!_.isEmpty(data?.images) && (
        <BannerCarousel data={manipulateArray(data?.images)} />
      )} */}
      <section
        className={`binduz-er-latest-news-area `}
        style={{ paddingTop: "30px" }}
      >
        <div className=" container">
          <div className="row">
            <motion.div
              className=" col-lg-9"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 1 }}
              transition={{ duration: 4 }}
              style={{ paddingRight: "40px", paddingLeft: "40px" }}
            >
              <div className="binduz-er-top-news-title">
                <h3 className="binduz-er-title">CLASSIFIED DETAIL</h3>
              </div>

              {!isLoading ? (
                <>
                  <div class="contact-info mb-2 mt-4">
                    <div
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        paddingTop: "5px",
                        paddingBottom: "15px",
                      }}
                    >
                      <SliderCom
                        settings={SliderForImage}
                        asNavFor={nav2}
                        selector={(slider1) => setNav1(slider1)}
                      >
                        {data?.images?.map((x) => (
                          <div>
                            <div
                              className="binduz-er-item"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                <img
                                  className="img-fluid  shadow"
                                  src={x?.image}
                                  style={{
                                    borderRadius: "20px",
                                    width: "100%",
                                    objectFit: "contain",
                                    backgroundColor: "black",
                                    height: "45vh",
                                    // border: "1px solid black",
                                  }}
                                  alt="img"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </SliderCom>
                    </div>
                    {data?.name && (
                      <p>
                        <span class="icon">👤</span>Name:{" "}
                        <span>{data?.name}</span>
                      </p>
                    )}
                    {data?.contact_person_name && (
                      <p>
                        <span class="icon">🏢</span>Contact Person Name:{" "}
                        <span> {data?.contact_person_name}</span>
                      </p>
                    )}
                    <p>
                      <span class="icon">📞</span>Mobile Number:{" "}
                      <span>{data?.phone_number}</span>
                    </p>
                    {data?.mobile && (
                      <p>
                        <span class="icon">📞</span>Alternative Mobile Number:{" "}
                        <span>{data?.mobile}</span>
                      </p>
                    )}
                    <p>
                      <span class="icon">✉️</span>Email:{" "}
                      <a
                        style={{ color: "#e74d58", fontWeight: "bold" }}
                        href={`mailto:${data?.email}`}
                      >
                        {data?.email}
                      </a>
                    </p>

                    <p>
                      <span class="icon">📅</span>Posted On:{" "}
                      <span> {formatDate(new Date(data?.start_date))}</span>
                    </p>
                    {data?.url && (
                      <p>
                        <span class="icon">💻</span>Web:{" "}
                        <span>{data?.url}</span>
                      </p>
                    )}
                  </div>

                  <div className="classifield-details-text mt-5">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.description,
                      }}
                    />
                  </div>

                  <div className="binduz-er-title">
                    <p>
                      When you call dont forget to mention you found this ad
                      from azindia.com
                      <br />
                    </p>
                  </div>
                </>
              ) : (
                <div style={{ marginTop: "101px" }}>
                  <Loader />
                </div>
              )}
            </motion.div>
            <div
              className=" col-lg-3"
              // style={{ paddingRight: "85px", paddingLeft: "55px" }}
            >
              <SideADModule isAd={false} isClassifield={true} isEvent={true} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainSection;
