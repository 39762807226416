/* eslint-disable no-unused-vars */
import React from "react";
import ClassiFieldForm from "./ClassiFieldForm";

const MainSection = () => {
  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div
            className=" col-lg-12"
            // style={{ paddingRight: "20px", paddingLeft: "20px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">UPDATE YOUR LOCAL BUSINESS</h3>
            </div>
            <ClassiFieldForm />
          </div>
         
        </div>
      </div>
    </section>
  );
};

export default MainSection;
